/*
  Possible Animations:
  enter
  exit
  appear Must set transitionAppear={true} on animation component
*/

.order-enter {
  transform: translateX(-120%);
  transition: 0.5s;
  max-height: 0;
  padding: 0 !important;
}

.order-enter-active {
  transform: translateX(0);
  /* padding: 2rem 0 !important; */
}

.order-exit {
  transition: 0.5s;
  transform: translateX(0);
}

.order-exit-active {
  transform: translateX(120%);
  padding: 0;
}

.count-enter {
  transition: .5s;
  transform: translateY(-100%);
}

.count-enter-active {
  transform: translateY(0);
}

.count-exit {
  transform: translateY(0);
  transition: .5s;
  /* left: 20px; */
  bottom: 20px;
}

.count-exit-active {
  transform: translateY(-200%) scale(4);
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: all 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: all 300ms;
}