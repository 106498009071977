/* https://www.freecodecamp.org/news/https-medium-com-nakayama-shingo-creating-responsive-tables-with-pure-css-using-the-grid-layout-module-8e0ea8f03e83/ */
/* 2 Column Card Layout */
@media screen and (max-width: 736px) {
  .collection-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .item {
    /* border: 1px solid #e5eafa; */
    border-radius: 8px;
    padding: 10px;
  }

  /* Don't display the first item, since it is used to display the header for tabular layouts*/
  .collection-container>li:first-child {
    display: none;
  }

  .attribute::before {
    content: attr(data-name);
  }

  /* Attribute name for first column, and attribute value for second column. */
  .attribute {
    display: grid;
    grid-template-columns: minmax(9em, 30%) 1fr;
  }
}

/* 1 Column Card Layout */
@media screen and (max-width:580px) {
  .collection-container {
    display: grid;
    grid-template-columns: 1fr;
  }
}

/* Tabular Layout */
@media screen and (min-width: 737px) {
  /* The maximum column width, that can wrap */
  .item-container {
    display: grid;
    grid-template-columns: 2em 4fr 6fr 1fr 1fr 1fr 1fr;
  }

  .attribute-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--column-width-min), 1fr));
  }

  /* Definition of wrapping column width for attribute groups. */
  .order-information {
    --column-width-min: 10em;
  }

  .order-id {
    --column-width-min: 10em;
  }

  .customer-information {
    --column-width-min: 4em;
  }

  .quantity {
    --column-width-min: 7em;
  }

  .amount {
    --column-width-min: 5em;
  }

  .tax {
    --column-width-min: 6em;
  }

  .fees {
    --column-width-min: 7em;
  }

  .collection {
    border-top: 1px solid #e5eafa;
  }

  /* In order to maximize row lines, only display one line for a cell */
  .attribute {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid #e5eafa;
    border-bottom: 1px solid #e5eafa;
    padding: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .collection-container>.item-container:first-child {
    background-color: #F4F7F9;
  }

  .item-container:hover {
    background-color: #F4F7F9;
  }

  /* Center header labels */
  .collection-container>.item-container:first-child .attribute {
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: initial;
    overflow: auto;
    white-space: normal;
  }
}