.pullRight {
  margin-left: auto!important;
}

.rounded {
  border-radius: 4px;
  overflow: hidden;
}

.rounded-m {
  border-radius: 8px;
  overflow: hidden;
}

.border {
  border: 1px solid #e5eafa;
}

.border-error {
  border: 1px solid #FF0063;
}

.border-bottom {
  border-bottom: 1px solid #e5eafa;
}

.border-top {
  border-top: 1px solid #e5eafa;
}

.border-left {
  border-left: 1px solid #e5eafa;
}

.border-none {
  border: none!important;
}

.border--blue {
  border-color: #0091FF;
}

.background-blue {
  background: #0091FF;
}

.background-lightBlue {
  background: #D4E2FC;
}

.background-main {
  background:#f7fafc;
}

.background-black {
  background: #333333;
}

.background-white {
  background:white!important;
}

.background-inputFill {
  background: #F4F7F9;
}

.background-info {
  background: #EEEEEE;
}

.background-green {
  background: #00c778;
}

.shadow {
  box-shadow: 0 2px 0 0 rgba(0,0,0,.03);

}

.shadow-s {
  box-shadow: 0 0 8px 0 rgba(0,0,0,.14);
}

.shadow-offset {
  box-shadow: 8px 8px 0px 0px #E9ECEF;
}

.shadow-spread {
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.07);
}

.error {
  border: 1px solid crimson!important;
  border-radius: 4px;
}

.text-error {
  color: #FF0063;
  font-size: 12px;
  text-align: left;
}