.flex {
  display: flex;
}

.flex-row {
  flex-direction: row!important;
}

.flex-column {
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-basis-half {
  flex-basis: 50%;
}

.flex-basis-thirds {
  flex-basis: 75%;
}

.flex-grid-half {
  display: flex;
  flex-wrap: wrap;
}

.flex-grid-half .col {
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(50% - 1rem); 
}

.flex-grid-thirds {
  display: flex;
  flex-wrap: wrap;
}

.flex-grid-thirds .col {
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(33.333333% - 1rem);
}

.flex-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-grid .col-4 {
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
  max-width: calc(25% - 1rem);
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-around {
  justify-content: space-around;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-align-center {
  align-items: center;
}

.flex-align-baseline {
  align-items: baseline;
}

.paddingLeft--small--onMobile {
  padding-left: 0;
}

.paddingRight--small--onMobile {
  padding-right: 0;
}

.marginTop--xLarge--onMobile {
  margin-top: 0;
}

.marginBottom--xLarge--onMobile {
  margin-top: 0;
}

@media only screen and (max-width: 600px) {
  .flex-grid-half .col {
    flex-basis: 100%;
    max-width: 100%;
  }

  .flex-grid-half.flex-row--onMobile .col {
    display: flex;
    flex: 0 0 calc(50% - 1rem); 
  }

  /* .flex-grid-half > * {
    margin-bottom: 1rem;
  } */

  .flex-grid-thirds .col {
    flex-basis: 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }

  .flex-grid .col-4 {
    flex-basis: 100%;
    max-width: 100%;
    margin-right: 0;
  }

  /* .flex-grid .col-4:last-child {
    flex-basis: 100%;
    max-width: 100%;
    margin-right: 0;
  } */

  .col:last-child {
    margin-bottom: 0!important;
  }

  .flex-column--onMobile {
    flex-direction: column;
  }

  .flex-row--onMobile {
    flex-direction: row;
  }

  .flex-align-start--onMobile {
    align-items: start;
  }

  .paddingLeft--small--onMobile {
    padding-left: 16px;
  }

  .paddingRight--small--onMobile {
    padding-right: 16px;
  }

  .marginTop--medium--onMobile {
    margin-top: 16px;
  }

  .marginBottom--medium--onMobile {
    margin-bottom: 16px;
  }

  .marginTop--xLarge--onMobile {
    margin-top: 72px;
  }

  .marginBottom--xLarge--onMobile {
    margin-bottom: 72px;
  }

  .order-1--onMobile {
    order: 1;
  }

  .order-2--onMobile {
    order: 2;
  }
}
