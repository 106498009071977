.selected {
  color: #0091FF!important;
  background: rgba(22,142,234,0.1);
}

.selected-ticketType .header {
  overflow: hidden;
  background: #2681ff!important;
}

.selected-ticketType .header p { color: white; }

.active-capsule {
  background: #F4F7F9;
  padding: 8px 16px;
  border-radius: 4px;
  color: #0091FF!important;
}

.modal {
  position: absolute;
  top: 25%;
  left: 24px;
  right: 24px;
  max-height: 65%;
  /* bottom: 24px; */
  /* position: relative;
  top: 50%;*/
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  background: white;
  border-radius: 8px;
  /* animation-name: modalSlide;
  animation-duration: .3s;
  transform: translateY(-50%); */
  overflow-y: auto;
}

.modal-guts {
  height: 100%;
}

.modal-guts > * {
  background-color: white;
  border-radius: 4px;
}

.close-button {
  position: absolute;
  
  /* don't need to go crazy with z-index here, just sits over .modal-guts */
  z-index: 1;
  
  top: 16px;
  right: 16px;
  /* needs to look OK with or without scrollbar */
  background: black;
  border: 0;
  border-radius: 4px;
  color: white;
  padding: 5px 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  background: rgba(0,0,0,.65)
}
/* 
@keyframes modalSlide {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(-50%);
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .modal {
    max-width: 100%;
    margin: 0 auto;
  }
} */

/* @media(hover: hover) and (pointer: fine) {} */