.text-bold {
  font-weight: bold;
}

.text-small {
  font-size: 12px;
}

.text-large {
  font-size: 24px;
  line-height: 40px;
}

.text-xLarge {
  font-size: 32px;
  line-height: 52px;
}

.text-number {
  font-family: 'Roboto', 'sans-serif';
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-spacing-1 {
  letter-spacing: 1px;
}

.text-white {
  color: white;
}

.text-pink {
  color: #C12D7C;
}

.text-purple {
  color: #531A8F;
}

.text-blue {
  color: #0091FF;
}

.text-grey {
  color: #757575;
}

.text-green {
  color: #23D160;
}

.text-ghost {
  opacity: 0.5;
}

.text-grey-blue {
  color: #95AAC9;
}