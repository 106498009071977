.margin--none {
  margin: 0!important;
}

.margin--large {
  margin: 32px;
}

.margin--medium {
  margin: 24px;
}

.margin--small {
  margin: 16px;
}

.margin--xSmall {
  margin: 8px;
}

/* MARGIN RIGHT */
.marginRight--xxSmall {
  margin-right: 4px;
}
.marginRight--xSmall {
  margin-right: 8px;
}

.marginRight--small {
  margin-right: 16px;
}

.marginRight--medium {
  margin-right: 24px;
}

.marginRight--large {
  margin-right: 32px;
}

.marginRight--large {
  margin-right: 32px;
}

.marginRight--none {
  margin-right: 0!important;
}

/* MARGIN LEFT */
.marginLeft--xxSmall {
  margin-left: 4px;
}

.marginLeft--xSmall {
  margin-left: 8px;
}

.marginLeft--small {
  margin-left: 16px;
}

.marginLeft--medium {
  margin-left: 24px;
}

.marginLeft--large {
  margin-left: 32px;
}

/* MARGIN TOP */
.marginTop--xxSmall {
  margin-top: 4px;
}
.marginTop--xSmall {
  margin-top: 8px;
}

.marginTop--small {
  margin-top: 16px;
}

.marginTop--medium {
  margin-top: 24px;
}

.marginTop--large {
  margin-top: 32px;
}

.marginTop--xLarge {
  margin-top: 52px;
}

/* MARGIN BOTTOM */
.marginBottom--xSmall {
  margin-bottom: 8px;
}
.marginBottom--xxSmall {
  margin-bottom: 4px;
}

.marginBottom--none {
  margin-bottom: 0px!important;
}

.marginBottom--small {
  margin-bottom: 16px;
}

.marginBottom--medium {
  margin-bottom: 24px!important;
}

.marginBottom--large {
  margin-bottom: 32px!important;
}

.marginBottom--xLarge {
  margin-bottom: 52px!important;
}