.padding--none {
  padding: 0!important;
}

/* PADDING */
.padding--xxSmall {
  padding: 4px;
}

.padding--xSmall {
  padding: 8px;
}

.padding--small {
  padding: 16px;
}

.padding--medium {
  padding: 24px!important;
}

.padding--large {
  padding: 32px;
}

.padding--xLarge {
  padding: 72px;
}

/* PADDING LEFT */
.paddingLeft--xSmall {
  padding-left: 8px;
}

.paddingLeft--small {
  padding-left: 16px;
}

.paddingLeft--medium {
  padding-left: 24px;
}

.paddingLeft--large {
  padding-left: 32px;
}

.paddingLeft--xLarge {
  padding-left: 52px;
}

.paddingLeft--none {
  padding-left: 0!important;
}

/* PADDING RIGHT */
.paddingRight--xSmall {
  padding-right: 8px;
}

.paddingRight--small {
  padding-right: 16px;
}

.paddingRight--medium {
  padding-right: 24px;
}

.paddingRight--large {
  padding-right: 32px;
}

.paddingRight--xLarge {
  padding-right: 52px;
}

.paddingRight--none {
  padding-right: 0!important;
}

/* PADDING TOP */
.paddingTop--xxSmall {
  padding-top: 4px;
}
.paddingTop--xSmall {
  padding-top: 8px;
}

.paddingTop--small {
  padding-top: 16px;
}

.paddingTop--medium {
  padding-top: 24px;
}

.paddingTop--large {
  padding-top: 32px;
}

.paddingTop--xLarge {
  padding-top: 72px;
}

.paddingTop--xxLarge {
  padding-top: 100px;
}

.paddingTop--none {
  padding-top: 0!important;
}

/* PADDING BOTTOM */
.paddingBottom--xxSmall {
  padding-bottom: 4px;
}

.paddingBottom--xSmall {
  padding-bottom: 8px;
}

.paddingBottom--small {
  padding-bottom: 16px;
}

.paddingBottom--medium {
  padding-bottom: 24px;
}

.paddingBottom--large {
  padding-bottom: 32px;
}

.paddingBottom--xLarge {
  padding-bottom: 72px;
}

.paddingBottom--xxLarge {
  padding-bottom: 100px;
}

.paddingBottom--none {
  padding-bottom: 0!important
}
